/* Swal */
.swal2-popup {
    background: #202020!important;
    color: #fad230 !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 14px !important;
    outline: none !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fad230 !important;
    color: #202020 !important;
    border: 1px solid #fad230 !important;
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-confirm:active {
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-confirm:focus {
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-confirm:hover {
    background: transparent !important;
    color: #fad230 !important;
    outline: none !important;
}

.swal2-styled.swal2-cancel {
    border: 1px solid #ff0000 !important;
    border-radius: 0.25em !important;
    background-color: #ff0000 !important;
    color: #202020 !important;
    outline: none !important;
}

.swal2-styled.swal2-cancel:active {
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-cancel:focus {
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-cancel:hover {
    background: transparent !important;
    color: #ff0000 !important;
    outline: none !important;
}

.swal2-styled:not([disabled]) {
    cursor: pointer !important;
}