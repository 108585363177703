.loginForm {
  width: 100%;
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: #202020;
  justify-content: center;
}
.loginForm .card {
  width: 450px;
  border: 1px solid #fad230;
  border-radius: 5px;
  padding: 30px;
  background-color: #202020;
  box-shadow: 1px 1px 0px #fcd849, 2px 2px 0px #fcd849, 3px 3px 0px #fcd849,
    4px 4px 0px #fcd849, 5px 5px 0px #fcd849, 6px 6px 0px #fcd849;
  transition: all 0.3s ease-in-out;
}

.loginForm .card h4 {
  color: #fad230;
  padding: 0;
  margin: 10px;
  font-size: 30px;
  align-items: center;
}
.loginForm .card small {
  color: #fad230c4;
  padding: 0;
  font-size: 10px;
}
/* .loginForm .card:hover{
  transform: scale(1.1);
} */
.loginForm .card input {
  margin-bottom: 20px;
  background-color: transparent;
  padding-left: 10px;
  font-size: 14px;
  color: #ffffff;
}
.input-container {
  display: flex;
  width: 100%;
}
.input-container input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-container .icon {
  width: 43px;
  padding: 15px 10px;
  line-height: 0.8;
  height: 42px;
  font-size: 14px;
  margin-top: 12px;
  border: 1px solid #fad230;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #202020;
  color: #fad230;
  cursor: pointer;
  text-align: center;
}

.loginForm .card .card-body #login-btn {
  color: #202020;
  cursor: pointer;
  background-color: #fad230;
  float: right;
  font-weight: 500;
  border: 1px solid #fad230;
  font-size: 16px;
  padding: 5px 18px;
  border-radius: 5px;
}
.loginForm .card .card-body #login-btn:hover {
  background: transparent;
  color: #fad230;
}
#change-password {
  background-color: #202020;
  color: #fad230;
  float: right;
  cursor: pointer;
  border: 1px solid #202020;
}
