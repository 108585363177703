@keyframes example {
  from {
    background-color: red;
  }
  to {
    background-color: yellow;
  }
}
@keyframes openNavBar {
  from {
    width: 5%;
  }
  to {
    width: 20%;
  }
}

@keyframes closeNavBar {
  from {
    width: 20%;
  }
  to {
    width: 5%;
  }
}

#leftToggle:hover ~ #rightToggle {
  color: red;
}
Nav:hover {
  background-color: green;
}

.loggedInUserModal {
  position: absolute;
  top: 20%;
  left: 75.6%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #202020;
  width: 300px;
  height: 300px;
  padding: 10px;
  border: 1px solid #fad230;
  box-shadow: 2px 5px 8px 4px gray;
}
.loggedInUserModal span {
  position: absolute;
  top: 0;
  right: 5%;
  padding: 10px;
  font-size: 20px;
  color: #ff0000;
  font-weight: 900;
  cursor: pointer;
}
.loggedInUserModal img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.loggedInUserModal h4 {
  padding-top: 15px;
  color: #fad230;
}
.loggedInUserModal .logoutBtn {
  padding: 6px 20px 6px 20px;
  background-color: #fad230;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 15px;
  font-size: 14px;
  color: #202020;
  font-weight: 600;
  cursor: pointer;
}
.loggedInUserModal p {
  margin-top: 15px;
  font-size: 16px;
  color: #fad230;
  text-decoration: underline;
  cursor: pointer;
}

