.lineup {
  display: flex;
  justify-content: center;
  width: 65%;
  background: green;
  margin: 20px auto;
  padding: 10px 20px;
  flex-direction: column;
}
.teamInfo:last-child {
  border-top: none;
  /* border-bottom: 1px solid #fff; */
}
.teamInfo {
  display: flex;
  width: 100%;
  /* height: 50px; */
  margin-bottom: 0px;
  padding-bottom: 0px;
  align-items: center;
  color: #202020;
  /* border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff; */
  justify-content: center;
}
.teamInfo span {
  margin: 0px 15px 0px 15px;
  font-size: 14px;
  font-weight: bold;
}
.teamInfo span:first-child {
  font-size: 10px !important;
}
.teamInfo .teamName{
  color:#ffffff;
}
.teamInfo span:last-child {
  color: #fad230;
}
.pitch {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  border: 1px solid white;
  position: relative;
}
.pitch .home,
.pitch .away {
  display: flex;
  height: 450px;
  flex-direction: column;
}
.lineup .pitch .half {
  /* margin: 450px 0px 0px -17px; */
  margin-left: -10px;
  width: 693px;
  border: 1px solid #eee;
}

.lineup .pitch .half:before {
  position: absolute;
  margin: -63px 43%;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 2px solid #eee;
  content: "";
}

.lineup .pitch .box1 {
  margin: -11px 40%;
  width: 120px;
  height: 60px;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.lineup .pitch .pen1 {
  margin: 1px 21px;
  width: 75px;
  height: 35px;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.lineup .pitch .box2 {
  margin: -10px 40%;
  width: 120px;
  height: 60px;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-top: 2px solid #eee;
}

.lineup .pitch .pen2 {
  margin: 23px 21px;
  width: 75px;
  height: 35px;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-top: 2px solid #eee;
}

.home .lineup_row {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: auto;
  margin-top: 0px !important;
}
.home .lineup_row:nth-child(4) {
  width: 80%;
}
.home .lineup_row:last-child {
  margin-bottom: 14%;
}
.away .keeper {
  display: flex;
  width: 90%;
  margin: auto;
  margin-bottom: 0px !important;
  align-items: end;
  text-align: center;
  justify-content: space-around;
}
.away .lineup_row:nth-child(2) {
  width: 80%;
}
.away .lineup_row:last-child {
  width: 90%;
}

.away .lineup_row:first-child {
  margin-top: 14%;
  width: 90%;
}

.player {
  display: flex;
  justify-content: center;
  width: 40px;
  /* height: auto; */
  border-radius: 20px;
  align-items: center;
  text-align: center;
  font-size: 9px;
  color: #202020;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  transition: 0.3s linear all;
  z-index: 0;
  flex: 1;
  position: relative;
  cursor: pointer;

  max-width: 190px;
}
.player p {
  margin-bottom: 0px;
  color: #202020;
  /* font-weight: bold; */
}

.player .rating {
  position: absolute;
  top: 10%;
  left: 45%;
  margin: 0px auto;
  transform: translate(-50%, -50%);
  
}
.badge {
  display: inline-block;
  padding: 3px 7px;
  font-size: 9px;
  font-weight: 700;
  line-height: 1;
  color: #202020;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 20px;
  /* background: red; */
}

.home .player .arrow-badge {
  position: absolute;
  top: -9%;
  left: 30%;
  margin: 0px 5px;
  transform: translate(-50%, -50%) !important;
}
.away .player .arrow-badge {
  position: absolute;
  top: -20%;
  left: 20%;
  margin: 0px 5px;
}

.arrow-badge {
  position: absolute;
  padding: 1px;
  border-radius: 50%;
  font-size: 7px;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bolder;
  line-height: 1;
  color: white;
  text-align: center;
  vertical-align: baseline;
}
.timeSubbedOff {
  padding-left: 12px;
  color: #202020;
  font-size: 9px;
}
.arrow {
   
  position: absolute;
  padding: 1px;
  border-radius: 50%;
  font-size: 7px;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bolder;
  color: white;
  text-align: center;
  background: #202020;
  border: 2px solid white;
  vertical-align: baseline;
}
.home .keeper img {
  background-color: #0f4b17;
  padding: 3px;
}
.keeper .player {
  text-align: center;
}
.keeper p {
  min-width: 100px;
  text-align: center;
}
.home .keeper .rating {
  margin: 0px 35px !important;
}
.away .keeper .rating {
  margin: 0px 35px !important;
}

.away .keeper img {
  background-color: #2020209a;
  padding: 3px;
}
.player img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

/* lineup_btn */
.lineup_btn {
  margin-top: 10px;
}

/* Bench */
.benchContiner {
  display: flex;
  padding: 15px;
  margin-top: 20px;
}
.coach {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #202020;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  margin: 20px 0px;
}
.coach .left,
.coach .right {
  min-width: 520px;
}
.coach .right {
  display: flex;
  align-items: center;
  justify-content: end;
}
.coach .center {
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(#202020, #fad230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 24px;
}
.bench {
  justify-content: center;
}
.bench h3 {
  font-size: 24px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(#202020, #fad230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
}
.bench_player {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin: 10px 0px;
  font-size: 14px;
  font-weight: bold;
}
.bench_list {
  display: flex;
  min-width: 530px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 13px 0px;
}
.coach img,
.bench img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #0f4b17b1;
  /* background-color: #202020b5; */
  padding: 5px;
}
.coach .left img,
.bench .left img {
  margin-right: 14px;
}
.coach .right img,
.bench .right img {
  margin-left: 14px;
}
.bench_player .left span {
  padding: 0 12px;
}
.bench_player .right span {
  padding: 0 12px;
}
.bench_rating {
  display: inline-block;
  background-color: orange;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
}
.bench_shirt {
  font-weight: normal;
}
.left small .bench_arrow,
.right small .bench_arrow {
  margin-left: 10px;
  padding: 1px 2px;
  border-radius: 50%;
  border: 3px solid #202020;
  background: #fff;
  color: #202020;
  font-weight: bold;
}

/* match facts */
.match_facts {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
}
.facts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  /* background: red; */
  /* margin: auto; */
}
.facts {
  font-size: 14px;
}
.facts .right {
  min-width: 515px;
  text-align: left;
  padding-left: 10px;
}
.facts .left {
  min-width: 515px;
  text-align: right;
  padding-right: 10px;
}
.facts .center {
  min-width: 100px;
  text-align: center;
  align-items: center;
}
.facts .center p {
  background: #f1f1f1;
  padding: 6px 12px;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.left .substitution {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
.substitution img {
  width: 35px;
  height: 35px;
}
.substitution p {
  margin-bottom: 0;
  font-size: 14px;
}
.substitution .name p:first-child {
  color: green;
}
.substitution .name p:last-child {
  color: red;
}
.right .substitution {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* display: inline-block; */
}
