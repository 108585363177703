* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
}
.modal-wrapper {
  position: absolute;
  top: 20%;
  width: 69.6%;
  /* width: 70%; */
  margin-top: 0;
  padding-top: 0;
  margin: auto;
  background-color: #363636;
  transition: all 1.3s ease-in-out;
  border: 1px solid #fad230;
  /* z-index: -1; */
}
.modal-wrapper.add-new-url {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  min-height: 383px;
  margin-top: 0;
  margin: auto;
  background-color: gray;
  transition: all 1.3s ease-in-out;
  border: 1px solid #fad230;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #202020;
  padding: 10px 20px;
}
.modalHeader h3 {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 0px;
  text-align: center;
  color: #fad230;
  /* background: -webkit-linear-gradient(#202020; #FAD230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.modalHeader span {
  align-items: center;
  /* margin-top: 0px; */
  /* margin-bottom: 8px; */
  font-size: 24px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}
.modalContent {
  padding: 1rem 1rem;
}
.modalBody {
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.modalBody h4 {
  font-size: 1.8rem;
  /* color: #3A3365; */
}

.modalBody button {
  margin-bottom: 0;
}
.modalBody #submit-btn {
  margin-bottom: 0;
  font-weight: 500;
  /* color: #202020; */
}

/* modal box for livestreamCreate */
.modalBody.livestreamCreate {
  overflow-y: scroll;
  max-height: 60vh !important;
}
.modalBody.livestreamCreate::-webkit-scrollbar {
  width: 5px;
}
.modalBody.livestreamCreate::-webkit-scrollbar-track {
  background: #3e3d3d;
  border-radius: 25px;
}
.modalBody.livestreamCreate::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}

.player_detail_modal-wrapper {
  position: absolute;
  top: 20%;
  left: 48%;
  width: 25%;
  margin-top: 0;
  padding-top: 0;
  margin: 0 auto;
  background-color: #363636;
  transition: all 1.3s ease-in-out;
  border: 1px solid #fad230;
  z-index: 1;
}
.detail_modal_header {
  background: #202020;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  align-items: center;
  height: auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.detail_modal_header span {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}
.detail_modal_header .team_logo {
  padding-top: 20px;
  position: absolute;
  top: 55%;
  left: 57%;
  transform: translate(-50%, -50%) !important;
}

.detail_modal_header h3 {
  font-size: 16px;
  color: #fad230;
  padding-top: 20px;
  margin: 0;
}

.detail_modal_header .badge {
  display: inline-block;
  padding: 5px 7px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  color: #202020;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.detail_modal_header .team_logo.badge {
  padding: 0px !important;
}

.detail_modal_header img {
  border-radius: 50%;
}
.playerDetailModalBody .mainDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.playerDetailModalBody .mainDetail h6 {
  font-size: 14px;
}
.playerDetailModalBody .mainDetail p {
  font-size: 11px;
  color: #fff;
}
.playerDetailModalBody .top_stats ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.playerDetailModalBody .top_stats ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.playerDetailModalBody .top_stats p {
  color: white;
  font-size: 14px;
}
.player_detail {
  height: 35vh;
  overflow-y: scroll;
  padding: 0px 10px 0px 0px;
}
.player_detail::-webkit-scrollbar {
  width: 3px;
}

.player_detail:hover::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 3px;
}

/* quiz */
.dateForQuiz {
  display: flex;
  justify-content: left;
  align-items: center;
  /* width: 50%; */
  margin: auto;
}

.dateForQuiz .quiz_date {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  justify-content: left;
}
.quiz_date p {
  font-family: "Roboto", sans-serif;
  color: #fad230;
  margin-bottom: 0px;
  /* padding-right: 10px; */
  font-weight: normal;
  font-size: 15px;
}

.quiz_date input[type="date"] {
  font-size: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  outline: none;
  background: #fad230;
}

.quiz_date input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  color: #fad230;
}
.quiz_date input[type="date"]::-webkit-calendar-picker-indicator {
  color: #fad230;
}
.quiz_date input[type="date"]::-webkit-calendar-picker-indicator::after {
  content: "";
  display: block;
  background-size: 10%;
  color: #fad230;
  width: 100px;
  height: 100px;
  position: absolute;
  transform: translateX(-2%);
}
.quiz_date input:active {
  border: none;
  outline: none;
  box-shadow: none;
}

/* zoom image */
.zoomModal {
  display: flex;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;

  margin: auto;
  background-color: #363636;
  transition: all 1.3s ease-in-out;
  border-radius: 0px;
  z-index: 1;
  justify-content: center;
}
.zoomImg {
  position: relative;
  margin-top: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: auto;
  border-radius: 0px;
  max-height: 70vh;
  z-index: 1;
  cursor: pointer;
}

.module {
  display: flex;
  min-height: 200px;
  flex-direction: column;
}
.module {
  overflow: hidden;
  overflow-y: scroll;
  height: 250px;
}
.module .fixed {
  top: 0;
  z-index: 2;
  position: sticky;
}

.module::-webkit-scrollbar {
  width: 3px;
}
.module::-webkit-scrollbar-track {
  background: #202020;
}
.module::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}
.module::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

.module_list {
  color: #fad230;
  align-items: center;
  margin-bottom: 15px;
}

.module_list span {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.module_list .checkbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.module_list .module_item {
  margin-right: 20px;
}

.linebreak {
  color: #e7cf6f;
}
