table {
  font-size: 14px;
  cursor: pointer;
  color: #565656;
  width: 100%;
  margin-bottom: 0px;
  z-index: 0;
}
table thead {
  position: relative;
  background-color: #202020;
  /* z-index: -999; */
}
tbody tr {
  height: 60px;
  align-items: center;
  justify-content: center;
  transition: padding 0.4s;
}
thead th {
  height: 40px;
  color: #fad230;
  text-align: center;
}
tbody tr button {
  margin-right: 10px;
}
table .table {
  margin-bottom: 0px;
}
tbody td {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  font-weight: 400;
}
tbody td:last-child {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
td img {
  width: 39px;
  /* height: 60px; */
  height: auto;
  max-height: 60px;
  padding: 3px 0px;
  transition: 0.2s;
}
td img:hover {
  transform: scale(2.2);
}

tr:nth-child(even) {
  background-color: #f0f6f8;
}
tbody tr:hover {
  background-color: #868585;
  padding-top: 5px;
  padding-bottom: 5px;
  /* color: #ffffff; */
  color: #202020;
  font-weight: bold;
}
select {
  height: 42px;
}
.actions {
  display: flex;
  align-items: center;
  margin-top: 1px;
}

/* User Detail */
.user_detail {
  text-decoration: none;
  color: #fad230 !important;
}
.quiz_detail {
  text-decoration: none;
  color: #fad230 !important;
}

.user_detail_card {
  width: 100%;
  margin: auto;
  padding: 0px;
  /* padding: 0px 15px 0px 15px; */
  /* box-shadow: 1px 5px 8px 0px rgba(128, 128, 128, 0.75);
  -webkit-box-shadow: 1px 5px 8px 0px rgba(128, 128, 128, 0.75);
  -moz-box-shadow: 1px 5px 8px 0px rgba(128, 128, 128, 0.75); */
  /* box-shadow: 1px 5px 8px 0px rgba(145, 141, 22, 0.75);
  -webkit-box-shadow: 1px 5px 8px 0px rgba(145, 141, 22, 0.75);
  -moz-box-shadow: 1px 5px 8px 0px rgba(145, 141, 22, 0.75); */
}
.user_detail_card {
  justify-content: center;
  text-align: center;
  align-items: center;
}
.user_detail_card h5 {
  font-size: 25px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  /* border-bottom: 5px solid #fad230; */
  display: inline-block;
  align-self: center;
  font-family: "Roboto", sans-serif;

  margin-top: 0px;
  margin-bottom: 15px !important;
  color: #202020;
}
.user_detail_card .nav {
  flex: 1;
  justify-content: center;
  flex-direction: row;
}
.user_detail_card .nav button {
  margin: auto 10px;
  padding: 7px 22px;
  border: 1px solid #202020;
  margin-bottom: 15px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.info-list {
  padding: 0px 20px !important;
}
.info_item {
  display: flex;
  justify-content: space-between;
  /* min-height: 50px; */
  height: 50px;
  align-items: center;
}
.info_item p {
  align-items: center;
}
.user_data {
  font-size: 16px;
  font-weight: bold;
}

/* user detail */

/* new rows form */

.new_row_form .choose_item {
  padding: 0px 20px;
  border-radius: 5px;
  border: none;
  background: #575656;
  color: #ffffff;
  font-size: 14px;
}
.new_row_form p {
  margin-bottom: 0;
  padding-bottom: 0;
}
.new_row_form .close {
  color: blue;
  font-weight: bold;
}
.new_row_form input {
  padding: 0px 20px;
  border-radius: 5px;
  border: none;
  width: 80px;
  text-align: center;
  background: #eceaea;
  font-size: 14px;
}
.new_row_form select {
  padding: 0px 20px;
  border-radius: 5px;
  border: none;
  background: #eceaea;
  font-size: 14px;
  width: 120px;
  height: 24px;
  overflow-y: scroll;
  font-size: 14px;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../../images/icons/caret-down.svg");
  background-size: 9px 13px;
  background-repeat: no-repeat;
  background-position-x: 88%;
  background-position-y: 5px;
}

.new_row_form input:focus,
.new_row_form select:focus {
  outline: none;
}
