.loader {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.loadingText {
  font-weight: 400;
  margin-bottom: 20px;
  letter-spacing: 5px;
  color: rgb(0, 0, 0);
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  animation-name: bounce_loadingText;
  animation-duration: 2.09s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

#loadingText1 {
  animation-delay: 0.75s;
}
#loadingText2 {
  animation-delay: 0.9s;
}
#loadingText3 {
  animation-delay: 1.05s;
}
#loadingText4 {
  animation-delay: 1.2s;
}
#loadingText5 {
  animation-delay: 1.35s;
}
#loadingText6 {
  animation-delay: 1.5s;
}
#loadingText7 {
  animation-delay: 1.65s;
}

@keyframes bounce_loadingText {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}


