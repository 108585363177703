/* .selectbox {
  display: flex;
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 10px;
  border: 1px solid #fad230;
  border: 1px solid grey;
  float: left;
  background: #f1f1f1;
  cursor: pointer;

}
.selectbox .caretDown_icon{
  display: flex;
  float: right;
  justify-content: end;
} */
.selectbox {
  /* padding: 8px 10px; */
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  margin-top: 12px;
  float: left;
  width: 100%;
  height: 42px;
  border: 1px solid #fad230;
  background: #ffffff;
  cursor: pointer;
  font-size: 15px;
}
.selectbox span {
  float: right;
}
.selectbox::placeholder {
  font-size: 16px;
}
/* Search with select */

.createSelectModal {
  position: absolute;
  top: 32.1%;
  left: 43.7%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 33.8%;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  border-radius: 7px;
  outline: none;
}
.createSelectModal.createSelectModalforAns {
  position: absolute;
  top: 30%;
  left: 43%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 40%;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  border-radius: 7px;
  outline: none;
}
.createSelectModal .close {
  position: absolute;
  top: 0;
  right: 5%;
  padding: 10px;
  font-size: 20px;
  color: #ff0000;
  font-weight: 900;
  cursor: pointer;
}
.createSelectModal .item-lists {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 8px;
  font-size: 14px;
  color: #202020;
  padding: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;
  max-height: 26vh;
}
.createSelectModal .item-lists .modelSelectSearchInput {
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  background-color: #f1f1f1;
  position: sticky;
}
.createSelectModal .item-lists p {
  margin: 5px 0;
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: left;

  display: flex;
  justify-content: space-between;
}
.createSelectModal strong {
  display: flex;
  justify-content: space-between;
}
.createSelectModal strong .league {
  font-weight: normal;
  font-size: 12px;
}
.createSelectModal .item-lists p small {
  display: flex;
  justify-content: space-between;
}
.createSelectModal .item-lists p:hover {
  background-color: #202020;
  color: #fad230;
}
.createSelectModal .item-lists .loadMore {
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  cursor: pointer;
  height: 35px;
  margin-top: 5px;
  width: 100%;
  background: #202020;
  color: #fad230;
}
.createSelectModal .item-lists .loadMore:hover {
  background-color: #fad230;
  color: #202020;
}
/* .createSelectModal .item-lists {
  overflow-y: scroll;
  max-height: 26vh;
} */

.createSelectModal .item-lists::-webkit-scrollbar {
  width: 5px;
}
.createSelectModal .item-lists::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 25px;
}
.createSelectModal .item-lists::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}
.createSelectModal .item-lists::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

.item-lists .add-new-item {
  margin: auto;
  text-decoration: none;
  text-align: center;
  align-self: center;
  color: #202020;
  font-size: 16px;
}
.item-lists .add-new-item i {
  font-weight: bold;
  color: #fad230;
}
.selectModalLineBreak {
  color: white;
  margin: 5px;
}
form.SelectSearchModal {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100% !important;
}
form.SelectSearchModal input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  float: left;
  width: 80%;
  height: 38px;
  background: #f1f1f1;
}

form.SelectSearchModal button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 13% !important;
  float: right;
  height: 38px;
  padding: 5px 10px;
  background: #202020;
  color: #fad230;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}

form.SelectSearchModal .input-group .input-group-text {
  font-size: 22px !important;
  font-weight: 500 !important;
  width: 5%;
  height: 38px;
  justify-content: end;
  line-height: 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid gray !important;
  border-bottom: 1px solid gray !important;
  cursor: pointer;
  background-color: #f1f1f1 !important;
}

.date {
  font-weight: normal;
  font-size: 12px;
}

/* choose for future */
.future_modal {
  width: 50% !important;
  position: absolute;
  top: 30.1% !important;
  left: 35.7% !important;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  border-radius: 7px;
  outline: none;
}
