@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,500;1,700&display=swap");

* {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* remove scrollbar */
  overflow-y: hidden;
  /* remove scrollbar */
  margin: 0;
  width: 100%;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unauthorized_page {
  background: #202020;
  color: #fad230;
  height: 100vh;
}

.unauthorized_page h1 {
  width: 100%;
  padding-top: 100px;
  text-align: center;
  align-items: center;
}

#submit-btn {
  /* margin-top: 7px; */
  float: right;
  font-weight: 500;
  color: #202020;
}

.select {
  height: 42px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #fad230;
  margin-top: 13px;
  overflow-y: scroll;
  font-size: 14px;
  outline: 0;
  cursor: pointer;
  /* appearance: none; */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #ffffff;
  color: #202020;
  background-image: url("./images/icons/caret-down.svg");
  background-size: 9px 13px;
  /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 12px;
}

.dropdown_select {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #fad230;
  overflow-y: scroll;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #f1f1f1;
  color: #202020;
  background-image: url("./images/icons/caret-down.svg");
  background-size: 9px 13px;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 8px;
  text-align: left;
  padding: 5px 10px;
  width: 43%;
  height: 37px;
  cursor: pointer;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

/* select::-ms-expand { display: none; } */
.select::-webkit-scrollbar {
  width: 2px;
}

.select::-webkit-scrollbar-track {
  background: #202020;
}

.select::-webkit-scrollbar-thumb {
  background-image: linear-gradient(red, #fad230);
  border-radius: 25px;
}

.select::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

/*  =====================Pagination =============================== */
.pagination-container {
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  /* font-weight: 500; */
  font-size: 15px;
  margin-top: 5px;
  padding-bottom: 25px;
  /* margin-bottom: 50px; */
}

.pagination-container .total_count {
  padding-top: 0;
  margin-top: 0;
}

.pagination-container .total_count span {
  font-weight: 500;
}

.pagination-container .item {
  cursor: pointer;
  list-style-type: none;
  font-weight: 500;
}

.pagination-container .link {
  cursor: pointer;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #202020;
  height: 40px;
  width: 40px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
  margin-bottom: 0px;
}

.pagination-container .pagination {
  /* margin-bottom: 0; */
  /* margin-bottom: 50px; */
}

.pagination-container .item.active {
  background-color: #fad230;
  color: #202020;
  border: 1px solid #202020;
}

.pagination-container .link.disabled {
  opacity: 0.2;
}

/* validation error */
.requiredError {
  margin: 0;
  padding: 0;
  color: #ec2121;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
}

/* Alert */

.alert-dismissible {
  font-size: 14px !important;
  margin-bottom: 10px;
  display: flex !important;
  align-items: center !important;
  height: 34px !important;
  line-height: 0px;
  padding: 0px 15px;
}

.alert-dismissible .btn-close {
  position: absolute;
  right: 0;
}

.alert-dismissible .btn-close:hover {
  outline: none;
  box-shadow: none;
}

.alert-dismissible .btn-close:focus {
  outline: none;
  box-shadow: none;
}

.alert-dismissible .btn-close:active {
  outline: none;
  box-shadow: none;
}

.loading {
  background: none;
  text-align: center;
  justify-content: center;
}

/* image upload */
.image-upload {
  display: inline;
  padding-top: 10px;
}

.image-upload label {
  padding-top: 14px;
  color: #fad230;
  cursor: pointer;
}

.image-upload .icon {
  padding-right: 10px;
  font-size: 30px;
  padding-top: 8px;
}

.image-upload .uploadedImage {
  display: flex;
  border-radius: 7px;
  margin-top: 10px;
}

/* react-multi-select */

.css-b62m3t-container {
  font-size: 16px;
  border: none;
  border-radius: 5px;
  outline: none;
  /* margin-top: 20px; */
  margin-top: 12px;
}

.css-1pahdxg-control {
  border-color: #fad230 !important;
}

.css-1pahdxg-control:hover {
  border-color: #fad230 !important;
  /* height: 200px; */
}

/* .css-26l3qy-menu {
  display: block;
  width: 100%;
  margin-top: 0px !important;
  padding-top: 0px !important;
  background-color: green; 
  overflow-y: scroll;
  max-height: 220px !important;
}
.css-26l3qy-menu::-webkit-scrollbar {
  width: 5px;
}
.css-26l3qy-menu::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 25px;
}
.css-26l3qy-menu::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
} */

.css-1s2u09g-control {
  font-size: 16px;
}

.css-1s2u09g-control:focus {
  outline: none;
  border: 1px solid #fad230;
}

.css-1s2u09g-control:active {
  outline: none;
  border: 1px solid #fad230;
}

/* full page loader */
.full_page_loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreStats .back {
  color: #202020;
  text-decoration: none;
  float: end;
  padding: 0px;
  font-size: 16px;
}

.back:hover {
  color: #fad230;
}

/* quiz Info */
.quiz_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quiz_info p {
  padding-bottom: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}

.quiz_info b {
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(#202020, #fad230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* header from highlight */
.header_highlight {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 7px; */
  margin-top: -1px;
}

.header_highlight .header_highlight_key,
.header_highlight .header_highlight_value {
  /* margin-top: -3px; */
  margin-right: 10px;
}

.header_highlight_btn {
  padding: 0px 8px 0px 8px;
  outline: none;
  border: none;
  height: 40px;
  margin-top: 12px;
  border-radius: 5px;
}

.correct_answer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background: #68db83; */
  padding: 16px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 10px;
}

.correct_answer h4 {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(#202020, #fad230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.correct_answer strong {
  color: #202020;
}

.correct_answer .correct_answer_card {
  display: flex;
  flex-direction: row;
}

.correct_answer .correct_answer_card .correct_answer_key {
  flex: 1;
}

.correct_answer .correct_answer_card .correct_answer_value {
  flex: 11;
}

/* add header */
.addHeader {
  width: 200px;
  text-align: center;
  margin-top: 12px;
  border-radius: 4px;
  background-color: #fad230;
  color: #202020;
  font-size: 12px;
  border: none;
  outline: none;
}

.addHeader:hover {
  outline: none;
  border: none;
}

.addHeader:focus {
  outline: none;
  border: none;
}

.addHeader:active {
  outline: none;
  border: none;
}

/* Switch */
.switch {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* margin-top: 10px; */
  padding-top: 12px;
  margin: 10px 0px 14px 0px;
}

.tableScroll {
  overflow: hidden;
  overflow-y: scroll;
  /* height: auto; */
  max-height: 500px;
}

.tableScroll .fixed {
  top: 0;
  z-index: 2;
  position: sticky;
}

.tableScroll::-webkit-scrollbar {
  width: 3px;
}

.tableScroll::-webkit-scrollbar-track {
  background: #202020;
}

.tableScroll::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}

.tableScroll::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

/* download button */
.downloadBtn {
  float: right;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: #fad230;
  color: #202020;
  border: none;
}