@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,500;1,700&display=swap);
* {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* remove scrollbar */
  overflow-y: hidden;
  /* remove scrollbar */
  margin: 0;
  width: 100%;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unauthorized_page {
  background: #202020;
  color: #fad230;
  height: 100vh;
}

.unauthorized_page h1 {
  width: 100%;
  padding-top: 100px;
  text-align: center;
  align-items: center;
}

#submit-btn {
  /* margin-top: 7px; */
  float: right;
  font-weight: 500;
  color: #202020;
}

.select {
  height: 42px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #fad230;
  margin-top: 13px;
  overflow-y: scroll;
  font-size: 14px;
  outline: 0;
  cursor: pointer;
  /* appearance: none; */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #ffffff;
  color: #202020;
  background-image: url(/static/media/caret-down.59c78c8b.svg);
  background-size: 9px 13px;
  /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 12px;
}

.dropdown_select {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #fad230;
  overflow-y: scroll;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #f1f1f1;
  color: #202020;
  background-image: url(/static/media/caret-down.59c78c8b.svg);
  background-size: 9px 13px;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 8px;
  text-align: left;
  padding: 5px 10px;
  width: 43%;
  height: 37px;
  cursor: pointer;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

/* select::-ms-expand { display: none; } */
.select::-webkit-scrollbar {
  width: 2px;
}

.select::-webkit-scrollbar-track {
  background: #202020;
}

.select::-webkit-scrollbar-thumb {
  background-image: linear-gradient(red, #fad230);
  border-radius: 25px;
}

.select::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

/*  =====================Pagination =============================== */
.pagination-container {
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  /* font-weight: 500; */
  font-size: 15px;
  margin-top: 5px;
  padding-bottom: 25px;
  /* margin-bottom: 50px; */
}

.pagination-container .total_count {
  padding-top: 0;
  margin-top: 0;
}

.pagination-container .total_count span {
  font-weight: 500;
}

.pagination-container .item {
  cursor: pointer;
  list-style-type: none;
  font-weight: 500;
}

.pagination-container .link {
  cursor: pointer;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #202020;
  height: 40px;
  width: 40px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
  margin-bottom: 0px;
}

.pagination-container .pagination {
  /* margin-bottom: 0; */
  /* margin-bottom: 50px; */
}

.pagination-container .item.active {
  background-color: #fad230;
  color: #202020;
  border: 1px solid #202020;
}

.pagination-container .link.disabled {
  opacity: 0.2;
}

/* validation error */
.requiredError {
  margin: 0;
  padding: 0;
  color: #ec2121;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
}

/* Alert */

.alert-dismissible {
  font-size: 14px !important;
  margin-bottom: 10px;
  display: flex !important;
  align-items: center !important;
  height: 34px !important;
  line-height: 0px;
  padding: 0px 15px;
}

.alert-dismissible .btn-close {
  position: absolute;
  right: 0;
}

.alert-dismissible .btn-close:hover {
  outline: none;
  box-shadow: none;
}

.alert-dismissible .btn-close:focus {
  outline: none;
  box-shadow: none;
}

.alert-dismissible .btn-close:active {
  outline: none;
  box-shadow: none;
}

.loading {
  background: none;
  text-align: center;
  justify-content: center;
}

/* image upload */
.image-upload {
  display: inline;
  padding-top: 10px;
}

.image-upload label {
  padding-top: 14px;
  color: #fad230;
  cursor: pointer;
}

.image-upload .icon {
  padding-right: 10px;
  font-size: 30px;
  padding-top: 8px;
}

.image-upload .uploadedImage {
  display: flex;
  border-radius: 7px;
  margin-top: 10px;
}

/* react-multi-select */

.css-b62m3t-container {
  font-size: 16px;
  border: none;
  border-radius: 5px;
  outline: none;
  /* margin-top: 20px; */
  margin-top: 12px;
}

.css-1pahdxg-control {
  border-color: #fad230 !important;
}

.css-1pahdxg-control:hover {
  border-color: #fad230 !important;
  /* height: 200px; */
}

/* .css-26l3qy-menu {
  display: block;
  width: 100%;
  margin-top: 0px !important;
  padding-top: 0px !important;
  background-color: green; 
  overflow-y: scroll;
  max-height: 220px !important;
}
.css-26l3qy-menu::-webkit-scrollbar {
  width: 5px;
}
.css-26l3qy-menu::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 25px;
}
.css-26l3qy-menu::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
} */

.css-1s2u09g-control {
  font-size: 16px;
}

.css-1s2u09g-control:focus {
  outline: none;
  border: 1px solid #fad230;
}

.css-1s2u09g-control:active {
  outline: none;
  border: 1px solid #fad230;
}

/* full page loader */
.full_page_loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreStats .back {
  color: #202020;
  text-decoration: none;
  float: end;
  padding: 0px;
  font-size: 16px;
}

.back:hover {
  color: #fad230;
}

/* quiz Info */
.quiz_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quiz_info p {
  padding-bottom: 10px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}

.quiz_info b {
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(#202020, #fad230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* header from highlight */
.header_highlight {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 7px; */
  margin-top: -1px;
}

.header_highlight .header_highlight_key,
.header_highlight .header_highlight_value {
  /* margin-top: -3px; */
  margin-right: 10px;
}

.header_highlight_btn {
  padding: 0px 8px 0px 8px;
  outline: none;
  border: none;
  height: 40px;
  margin-top: 12px;
  border-radius: 5px;
}

.correct_answer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background: #68db83; */
  padding: 16px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 10px;
}

.correct_answer h4 {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(#202020, #fad230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.correct_answer strong {
  color: #202020;
}

.correct_answer .correct_answer_card {
  display: flex;
  flex-direction: row;
}

.correct_answer .correct_answer_card .correct_answer_key {
  flex: 1 1;
}

.correct_answer .correct_answer_card .correct_answer_value {
  flex: 11 1;
}

/* add header */
.addHeader {
  width: 200px;
  text-align: center;
  margin-top: 12px;
  border-radius: 4px;
  background-color: #fad230;
  color: #202020;
  font-size: 12px;
  border: none;
  outline: none;
}

.addHeader:hover {
  outline: none;
  border: none;
}

.addHeader:focus {
  outline: none;
  border: none;
}

.addHeader:active {
  outline: none;
  border: none;
}

/* Switch */
.switch {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* margin-top: 10px; */
  padding-top: 12px;
  margin: 10px 0px 14px 0px;
}

.tableScroll {
  overflow: hidden;
  overflow-y: scroll;
  /* height: auto; */
  max-height: 500px;
}

.tableScroll .fixed {
  top: 0;
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
}

.tableScroll::-webkit-scrollbar {
  width: 3px;
}

.tableScroll::-webkit-scrollbar-track {
  background: #202020;
}

.tableScroll::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}

.tableScroll::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

/* download button */
.downloadBtn {
  float: right;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: #fad230;
  color: #202020;
  border: none;
}
@keyframes example {
  from {
    background-color: red;
  }
  to {
    background-color: yellow;
  }
}
@keyframes openNavBar {
  from {
    width: 5%;
  }
  to {
    width: 20%;
  }
}

@keyframes closeNavBar {
  from {
    width: 20%;
  }
  to {
    width: 5%;
  }
}

#leftToggle:hover ~ #rightToggle {
  color: red;
}
Nav:hover {
  background-color: green;
}

.loggedInUserModal {
  position: absolute;
  top: 20%;
  left: 75.6%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #202020;
  width: 300px;
  height: 300px;
  padding: 10px;
  border: 1px solid #fad230;
  box-shadow: 2px 5px 8px 4px gray;
}
.loggedInUserModal span {
  position: absolute;
  top: 0;
  right: 5%;
  padding: 10px;
  font-size: 20px;
  color: #ff0000;
  font-weight: 900;
  cursor: pointer;
}
.loggedInUserModal img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.loggedInUserModal h4 {
  padding-top: 15px;
  color: #fad230;
}
.loggedInUserModal .logoutBtn {
  padding: 6px 20px 6px 20px;
  background-color: #fad230;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 15px;
  font-size: 14px;
  color: #202020;
  font-weight: 600;
  cursor: pointer;
}
.loggedInUserModal p {
  margin-top: 15px;
  font-size: 16px;
  color: #fad230;
  text-decoration: underline;
  cursor: pointer;
}


.loginForm {
  width: 100%;
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: #202020;
  justify-content: center;
}
.loginForm .card {
  width: 450px;
  border: 1px solid #fad230;
  border-radius: 5px;
  padding: 30px;
  background-color: #202020;
  box-shadow: 1px 1px 0px #fcd849, 2px 2px 0px #fcd849, 3px 3px 0px #fcd849,
    4px 4px 0px #fcd849, 5px 5px 0px #fcd849, 6px 6px 0px #fcd849;
  transition: all 0.3s ease-in-out;
}

.loginForm .card h4 {
  color: #fad230;
  padding: 0;
  margin: 10px;
  font-size: 30px;
  align-items: center;
}
.loginForm .card small {
  color: #fad230c4;
  padding: 0;
  font-size: 10px;
}
/* .loginForm .card:hover{
  transform: scale(1.1);
} */
.loginForm .card input {
  margin-bottom: 20px;
  background-color: transparent;
  padding-left: 10px;
  font-size: 14px;
  color: #ffffff;
}
.input-container {
  display: flex;
  width: 100%;
}
.input-container input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-container .icon {
  width: 43px;
  padding: 15px 10px;
  line-height: 0.8;
  height: 42px;
  font-size: 14px;
  margin-top: 12px;
  border: 1px solid #fad230;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #202020;
  color: #fad230;
  cursor: pointer;
  text-align: center;
}

.loginForm .card .card-body #login-btn {
  color: #202020;
  cursor: pointer;
  background-color: #fad230;
  float: right;
  font-weight: 500;
  border: 1px solid #fad230;
  font-size: 16px;
  padding: 5px 18px;
  border-radius: 5px;
}
.loginForm .card .card-body #login-btn:hover {
  background: transparent;
  color: #fad230;
}
#change-password {
  background-color: #202020;
  color: #fad230;
  float: right;
  cursor: pointer;
  border: 1px solid #202020;
}

table {
  font-size: 14px;
  cursor: pointer;
  color: #565656;
  width: 100%;
  margin-bottom: 0px;
  z-index: 0;
}
table thead {
  position: relative;
  background-color: #202020;
  /* z-index: -999; */
}
tbody tr {
  height: 60px;
  align-items: center;
  justify-content: center;
  transition: padding 0.4s;
}
thead th {
  height: 40px;
  color: #fad230;
  text-align: center;
}
tbody tr button {
  margin-right: 10px;
}
table .table {
  margin-bottom: 0px;
}
tbody td {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  font-weight: 400;
}
tbody td:last-child {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
td img {
  width: 39px;
  /* height: 60px; */
  height: auto;
  max-height: 60px;
  padding: 3px 0px;
  transition: 0.2s;
}
td img:hover {
  transform: scale(2.2);
}

tr:nth-child(even) {
  background-color: #f0f6f8;
}
tbody tr:hover {
  background-color: #868585;
  padding-top: 5px;
  padding-bottom: 5px;
  /* color: #ffffff; */
  color: #202020;
  font-weight: bold;
}
select {
  height: 42px;
}
.actions {
  display: flex;
  align-items: center;
  margin-top: 1px;
}

/* User Detail */
.user_detail {
  text-decoration: none;
  color: #fad230 !important;
}
.quiz_detail {
  text-decoration: none;
  color: #fad230 !important;
}

.user_detail_card {
  width: 100%;
  margin: auto;
  padding: 0px;
  /* padding: 0px 15px 0px 15px; */
  /* box-shadow: 1px 5px 8px 0px rgba(128, 128, 128, 0.75);
  -webkit-box-shadow: 1px 5px 8px 0px rgba(128, 128, 128, 0.75);
  -moz-box-shadow: 1px 5px 8px 0px rgba(128, 128, 128, 0.75); */
  /* box-shadow: 1px 5px 8px 0px rgba(145, 141, 22, 0.75);
  -webkit-box-shadow: 1px 5px 8px 0px rgba(145, 141, 22, 0.75);
  -moz-box-shadow: 1px 5px 8px 0px rgba(145, 141, 22, 0.75); */
}
.user_detail_card {
  justify-content: center;
  text-align: center;
  align-items: center;
}
.user_detail_card h5 {
  font-size: 25px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  /* border-bottom: 5px solid #fad230; */
  display: inline-block;
  align-self: center;
  font-family: "Roboto", sans-serif;

  margin-top: 0px;
  margin-bottom: 15px !important;
  color: #202020;
}
.user_detail_card .nav {
  flex: 1 1;
  justify-content: center;
  flex-direction: row;
}
.user_detail_card .nav button {
  margin: auto 10px;
  padding: 7px 22px;
  border: 1px solid #202020;
  margin-bottom: 15px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.info-list {
  padding: 0px 20px !important;
}
.info_item {
  display: flex;
  justify-content: space-between;
  /* min-height: 50px; */
  height: 50px;
  align-items: center;
}
.info_item p {
  align-items: center;
}
.user_data {
  font-size: 16px;
  font-weight: bold;
}

/* user detail */

/* new rows form */

.new_row_form .choose_item {
  padding: 0px 20px;
  border-radius: 5px;
  border: none;
  background: #575656;
  color: #ffffff;
  font-size: 14px;
}
.new_row_form p {
  margin-bottom: 0;
  padding-bottom: 0;
}
.new_row_form .close {
  color: blue;
  font-weight: bold;
}
.new_row_form input {
  padding: 0px 20px;
  border-radius: 5px;
  border: none;
  width: 80px;
  text-align: center;
  background: #eceaea;
  font-size: 14px;
}
.new_row_form select {
  padding: 0px 20px;
  border-radius: 5px;
  border: none;
  background: #eceaea;
  font-size: 14px;
  width: 120px;
  height: 24px;
  overflow-y: scroll;
  font-size: 14px;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/static/media/caret-down.59c78c8b.svg);
  background-size: 9px 13px;
  background-repeat: no-repeat;
  background-position-x: 88%;
  background-position-y: 5px;
}

.new_row_form input:focus,
.new_row_form select:focus {
  outline: none;
}

/* Swal */
.swal2-popup {
    background: #202020!important;
    color: #fad230 !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 14px !important;
    outline: none !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fad230 !important;
    color: #202020 !important;
    border: 1px solid #fad230 !important;
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-confirm:active {
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-confirm:focus {
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-confirm:hover {
    background: transparent !important;
    color: #fad230 !important;
    outline: none !important;
}

.swal2-styled.swal2-cancel {
    border: 1px solid #ff0000 !important;
    border-radius: 0.25em !important;
    background-color: #ff0000 !important;
    color: #202020 !important;
    outline: none !important;
}

.swal2-styled.swal2-cancel:active {
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-cancel:focus {
    outline: none !important;
    box-shadow: none !important;
}

.swal2-styled.swal2-cancel:hover {
    background: transparent !important;
    color: #ff0000 !important;
    outline: none !important;
}

.swal2-styled:not([disabled]) {
    cursor: pointer !important;
}
/* .search .input-group{
  margin-bottom: 30px!important;
  justify-content: center!important;
}
*/

.search .input-group {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  /* height: 38px; */
  flex-wrap: nowrap;
}

.search-city .input-group input[type="text"],
.search-township .input-group input[type="text"],
.search-league .input-group input[type="text"],
.search-team .input-group input[type="text"],
.search-highlight .input-group input[type="text"] {
  width: 34% !important;
}

.search .input-group input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  width: 45%;
  height: 37px;
  background-color: #f1f1f1 !important;
}

.search .input-group .input-group-text,
.match-search-form .input-group .input-group-text,
form.searchModal .input-group .input-group-text {
  font-size: 22px !important;
  font-weight: 500 !important;
  width: 4%;
  height: 37px;
  justify-content: end;
  line-height: 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid gray !important;
  border-bottom: 1px solid gray !important;
  cursor: pointer;
  background-color: #f1f1f1 !important;
}

.search-player .input-group input[type="text"] {
  width: 50% !important;
  font-size: 14px;
}
.search .input-group button,
form.searchModal button {
  width: 10%;
  height: 37px;
  outline: none;
  box-shadow: none;
  /* border: none; */
  padding: 5px 12px;
  background: #202020;
  color: #fad230;
  font-size: 17px;
  border: 1px solid grey;
  cursor: pointer;
}
.search .input-group .clear_filter {
  width: 7%;
  height: 37px;
  outline: none;
  box-shadow: none;
  /* border: none; */
  padding: 5px 12px;
  background: #f1f1f1;
  color: #202020;
  font-weight: bolder;
  border: 1px solid grey;
  cursor: pointer;

  font-size: 22px !important;
  font-weight: 500 !important;
  /* height: 37px; */
  /* justify-content: end; */
  line-height: 1 !important;
}
form.searchModal button {
  width: 15% !important;
}

.search-wallet .search_select,
.user-fav-team .search_select {
  width: 45% !important;
}
/* .search-highlight .search_select, .search-livestream .search_select{
  height: 37px!important;
} */
.search .search_select {
  text-align: left;
  padding: 5px 10px;
  font-size: 15px;
  width: 34%;
  height: 37px;
  cursor: pointer;
  background: #f1f1f1;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}
.search_select_team .search_select {
  width: 50% !important;
}
.search.search-team .search_select,
.search.search-highlight .search_select {
  width: 45%;
}
.search.search-player .search_select {
  width: 48%;
}
.search.search-livestream .search_select {
  width: 50%;
}

.dropdown_search span {
  float: right;
}

form.search button:hover i {
  color: #fad230;
}

form input:focus,
form select:focus {
  outline: none;
}

/* search form from dropdown */
form.searchModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}

form.searchModal input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  float: left;
  width: 360px;
  height: 37px;
  background-color: #f1f1f1 !important;
}

form.searchModal button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* line break */
.lineBreak {
  margin: 30px 0px;
}

/* model box */
.searchSelectModal {
  position: absolute;
  top: 18.3%;
  left: 43.4%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 37.7%;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  outline: none;
}

.futureDetailSearch {
  position: absolute;
  top: 31%;
  left: 43.4%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 37.7%;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  outline: none;
}

.searchSelectModal .close {
  position: absolute;
  top: 0;
  right: 5%;
  padding: 10px;
  font-size: 20px;
  color: #ff0000;
  font-weight: 900;
  cursor: pointer;
}

.searchSelectModal .item-lists .modelSearchInput {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  height: 35px;
  min-width: 150px;
  margin-bottom: 20px;
  outline: none;
  background-color: #f1f1f1;
}

.searchSelectModal .item-lists {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  color: #202020;
  padding: 10px;
  margin-bottom: 20px;
}

.searchSelectModal .item-lists p {
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  cursor: pointer;
  height: 35px;
  min-width: 160px;
  margin-right: 10px;
}

.searchSelectModal .item-lists p:hover {
  background-color: #202020;
  color: #fad230;
}

.searchSelectModal .item-lists .searchFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.searchSelectModal .item-lists .searchFooter span {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  height: 35px;
  width: 48%;
}

.searchSelectModal .item-lists .loadMore {
  border: 1px solid #202020;
  background: #202020;
  color: #fad230;
}

.searchSelectModal .item-lists .loadMore:hover {
  background-color: #fad230;
  color: #202020;
}

.searchSelectModal .item-lists .stopSearching {
  border: 1px solid #202020;
  color: #202020;
  background-color: #fad230;
}

.searchSelectModal .item-lists .stopSearching:hover {
  color: #fad230;
  background-color: #202020;
}

.searchSelectModal .item-lists {
  overflow-y: scroll;
  max-height: 45vh;
}

.searchSelectModal .item-lists::-webkit-scrollbar {
  width: 5px;
}

.searchSelectModal .item-lists::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 25px;
}

.searchSelectModal .item-lists::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}

.searchSelectModal .item-lists::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

.user-team-activity .input-group .search_select {
  width: 45% !important;
}
.match-quiz-search .input-group .search_select {
  width: 45% !important;
}
/* style for search_form */

.match-search-form .input-group {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  flex-wrap: nowrap;
}

.match-search-form .input-group input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  width: 16%;
  height: 37px;
  background-color: #f1f1f1 !important;
}

.match-search-form .input-group input[type="time" i] {
  font-size: 18px !important;
  align-items: center !important;
  font-weight: bolder !important;
  border: 1px solid grey !important;
  float: left !important;
  cursor: pointer;
  width: 4% !important;
  height: 37px !important;
  justify-content: center;
  padding: 0px 0px 0px 8px !important;
  background: #f1f1f1 !important;
  border-left: none !important;
  border-right: 2px solid gray !important;
}

.match-search-form .input-group input[type="date"] {
  font-size: 16px;
  align-items: center;
  font-weight: normal;
  border: 1px solid grey;
  float: left;
  width: 14%;
  height: 37px;
  padding: 0px 0px 0px 7px;
  background: #f1f1f1;
  border-left: none;
  cursor: pointer;
  border-right: 1px solid gray;
}


.match-search-form .input-group .search_select {
  padding: 5px 10px;
  font-size: 14px;
  width: 21%;
  height: 37px;
  cursor: pointer;
  background: #f1f1f1;
  border-right: 2px solid gray;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.match-search-form .input-group button {
  width: 7%;
  height: 37px;
  outline: none;
  box-shadow: none;
  padding: 5px 12px;
  background: #202020;
  color: #fad230;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}

.match-search-form
  .input-group
  input[type="date"]::-webkit-calendar-picker-indicator,
.match-search-form
  .input-group
  input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.search-prize .input-group input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  width: 30%;
  height: 37px;
  background-color: #f1f1f1 !important;
}
.search-prize .input-group input[type="date"] {
  font-size: 16px;
  align-items: center;
  font-weight: normal;
  border: 1px solid grey;
  float: left;
  width: 28%;
  height: 37px;
  padding: 0px 0px 0px 7px;
  background: #f1f1f1;
  border-left: none;
  cursor: pointer;
  border-right: 2px solid gray;
  border-left: 1px solid gray;
}
/* score */
form.search-score {
  /* width:500px; */
  justify-content: end;
  text-align: right;
}
form.search-score input[type="text"] {
  width: 100% !important;
}
form.search-score .input-group button {
  width: auto !important;
  margin-right: 0;
}

/* search with match quiz in quiz_details */
.item-lists .search_with_quiz {
  display: flex;
  width: 100% !important;
  min-width: 420px;
  /* min-width: 500px; */
}
.search_with_quiz {
  text-align: left;
  border: 1px solid #202020;
  border-radius: 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
  padding: 10px;
}
.loadMoreQuiz {
  min-width: 420px;
}
.search.activity_search .search_select {
  width: 50% !important;
}

.search-prize-history {
  width: 80% !important;
}
.search-prize-history .search_select {
  width: 38% !important;
}
.search-prize-history .input-group input[type="date"] {
  font-size: 16px;
  align-items: center;
  font-weight: normal;
  border: 1px solid grey;
  float: left;
  width: 38%;
  height: 37px;
  padding: 0px 0px 0px 7px;
  background: #f1f1f1;
  border-left: none;
  cursor: pointer;
  border-left: 1px solid gray;
}

.loader {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.loadingText {
  font-weight: 400;
  margin-bottom: 20px;
  letter-spacing: 5px;
  color: rgb(0, 0, 0);
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  animation-name: bounce_loadingText;
  animation-duration: 2.09s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

#loadingText1 {
  animation-delay: 0.75s;
}
#loadingText2 {
  animation-delay: 0.9s;
}
#loadingText3 {
  animation-delay: 1.05s;
}
#loadingText4 {
  animation-delay: 1.2s;
}
#loadingText5 {
  animation-delay: 1.35s;
}
#loadingText6 {
  animation-delay: 1.5s;
}
#loadingText7 {
  animation-delay: 1.65s;
}

@keyframes bounce_loadingText {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}



/* .selectbox {
  display: flex;
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 10px;
  border: 1px solid #fad230;
  border: 1px solid grey;
  float: left;
  background: #f1f1f1;
  cursor: pointer;

}
.selectbox .caretDown_icon{
  display: flex;
  float: right;
  justify-content: end;
} */
.selectbox {
  /* padding: 8px 10px; */
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  margin-top: 12px;
  float: left;
  width: 100%;
  height: 42px;
  border: 1px solid #fad230;
  background: #ffffff;
  cursor: pointer;
  font-size: 15px;
}
.selectbox span {
  float: right;
}
.selectbox::-webkit-input-placeholder {
  font-size: 16px;
}
.selectbox:-ms-input-placeholder {
  font-size: 16px;
}
.selectbox::placeholder {
  font-size: 16px;
}
/* Search with select */

.createSelectModal {
  position: absolute;
  top: 32.1%;
  left: 43.7%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 33.8%;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  border-radius: 7px;
  outline: none;
}
.createSelectModal.createSelectModalforAns {
  position: absolute;
  top: 30%;
  left: 43%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 40%;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  border-radius: 7px;
  outline: none;
}
.createSelectModal .close {
  position: absolute;
  top: 0;
  right: 5%;
  padding: 10px;
  font-size: 20px;
  color: #ff0000;
  font-weight: 900;
  cursor: pointer;
}
.createSelectModal .item-lists {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  font-size: 14px;
  color: #202020;
  padding: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;
  max-height: 26vh;
}
.createSelectModal .item-lists .modelSelectSearchInput {
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  background-color: #f1f1f1;
  position: -webkit-sticky;
  position: sticky;
}
.createSelectModal .item-lists p {
  margin: 5px 0;
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: left;

  display: flex;
  justify-content: space-between;
}
.createSelectModal strong {
  display: flex;
  justify-content: space-between;
}
.createSelectModal strong .league {
  font-weight: normal;
  font-size: 12px;
}
.createSelectModal .item-lists p small {
  display: flex;
  justify-content: space-between;
}
.createSelectModal .item-lists p:hover {
  background-color: #202020;
  color: #fad230;
}
.createSelectModal .item-lists .loadMore {
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  cursor: pointer;
  height: 35px;
  margin-top: 5px;
  width: 100%;
  background: #202020;
  color: #fad230;
}
.createSelectModal .item-lists .loadMore:hover {
  background-color: #fad230;
  color: #202020;
}
/* .createSelectModal .item-lists {
  overflow-y: scroll;
  max-height: 26vh;
} */

.createSelectModal .item-lists::-webkit-scrollbar {
  width: 5px;
}
.createSelectModal .item-lists::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 25px;
}
.createSelectModal .item-lists::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}
.createSelectModal .item-lists::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

.item-lists .add-new-item {
  margin: auto;
  text-decoration: none;
  text-align: center;
  align-self: center;
  color: #202020;
  font-size: 16px;
}
.item-lists .add-new-item i {
  font-weight: bold;
  color: #fad230;
}
.selectModalLineBreak {
  color: white;
  margin: 5px;
}
form.SelectSearchModal {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100% !important;
}
form.SelectSearchModal input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  float: left;
  width: 80%;
  height: 38px;
  background: #f1f1f1;
}

form.SelectSearchModal button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 13% !important;
  float: right;
  height: 38px;
  padding: 5px 10px;
  background: #202020;
  color: #fad230;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}

form.SelectSearchModal .input-group .input-group-text {
  font-size: 22px !important;
  font-weight: 500 !important;
  width: 5%;
  height: 38px;
  justify-content: end;
  line-height: 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid gray !important;
  border-bottom: 1px solid gray !important;
  cursor: pointer;
  background-color: #f1f1f1 !important;
}

.date {
  font-weight: normal;
  font-size: 12px;
}

/* choose for future */
.future_modal {
  width: 50% !important;
  position: absolute;
  top: 30.1% !important;
  left: 35.7% !important;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  border-radius: 7px;
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
}
.modal-wrapper {
  position: absolute;
  top: 20%;
  width: 69.6%;
  /* width: 70%; */
  margin-top: 0;
  padding-top: 0;
  margin: auto;
  background-color: #363636;
  transition: all 1.3s ease-in-out;
  border: 1px solid #fad230;
  /* z-index: -1; */
}
.modal-wrapper.add-new-url {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  min-height: 383px;
  margin-top: 0;
  margin: auto;
  background-color: gray;
  transition: all 1.3s ease-in-out;
  border: 1px solid #fad230;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #202020;
  padding: 10px 20px;
}
.modalHeader h3 {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 0px;
  text-align: center;
  color: #fad230;
  /* background: -webkit-linear-gradient(#202020; #FAD230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.modalHeader span {
  align-items: center;
  /* margin-top: 0px; */
  /* margin-bottom: 8px; */
  font-size: 24px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}
.modalContent {
  padding: 1rem 1rem;
}
.modalBody {
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.modalBody h4 {
  font-size: 1.8rem;
  /* color: #3A3365; */
}

.modalBody button {
  margin-bottom: 0;
}
.modalBody #submit-btn {
  margin-bottom: 0;
  font-weight: 500;
  /* color: #202020; */
}

/* modal box for livestreamCreate */
.modalBody.livestreamCreate {
  overflow-y: scroll;
  max-height: 60vh !important;
}
.modalBody.livestreamCreate::-webkit-scrollbar {
  width: 5px;
}
.modalBody.livestreamCreate::-webkit-scrollbar-track {
  background: #3e3d3d;
  border-radius: 25px;
}
.modalBody.livestreamCreate::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}

.player_detail_modal-wrapper {
  position: absolute;
  top: 20%;
  left: 48%;
  width: 25%;
  margin-top: 0;
  padding-top: 0;
  margin: 0 auto;
  background-color: #363636;
  transition: all 1.3s ease-in-out;
  border: 1px solid #fad230;
  z-index: 1;
}
.detail_modal_header {
  background: #202020;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  align-items: center;
  height: auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.detail_modal_header span {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}
.detail_modal_header .team_logo {
  padding-top: 20px;
  position: absolute;
  top: 55%;
  left: 57%;
  transform: translate(-50%, -50%) !important;
}

.detail_modal_header h3 {
  font-size: 16px;
  color: #fad230;
  padding-top: 20px;
  margin: 0;
}

.detail_modal_header .badge {
  display: inline-block;
  padding: 5px 7px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  color: #202020;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.detail_modal_header .team_logo.badge {
  padding: 0px !important;
}

.detail_modal_header img {
  border-radius: 50%;
}
.playerDetailModalBody .mainDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.playerDetailModalBody .mainDetail h6 {
  font-size: 14px;
}
.playerDetailModalBody .mainDetail p {
  font-size: 11px;
  color: #fff;
}
.playerDetailModalBody .top_stats ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.playerDetailModalBody .top_stats ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.playerDetailModalBody .top_stats p {
  color: white;
  font-size: 14px;
}
.player_detail {
  height: 35vh;
  overflow-y: scroll;
  padding: 0px 10px 0px 0px;
}
.player_detail::-webkit-scrollbar {
  width: 3px;
}

.player_detail:hover::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 3px;
}

/* quiz */
.dateForQuiz {
  display: flex;
  justify-content: left;
  align-items: center;
  /* width: 50%; */
  margin: auto;
}

.dateForQuiz .quiz_date {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  justify-content: left;
}
.quiz_date p {
  font-family: "Roboto", sans-serif;
  color: #fad230;
  margin-bottom: 0px;
  /* padding-right: 10px; */
  font-weight: normal;
  font-size: 15px;
}

.quiz_date input[type="date"] {
  font-size: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  padding: 5px;
  outline: none;
  background: #fad230;
}

.quiz_date input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  color: #fad230;
}
.quiz_date input[type="date"]::-webkit-calendar-picker-indicator {
  color: #fad230;
}
.quiz_date input[type="date"]::-webkit-calendar-picker-indicator::after {
  content: "";
  display: block;
  background-size: 10%;
  color: #fad230;
  width: 100px;
  height: 100px;
  position: absolute;
  transform: translateX(-2%);
}
.quiz_date input:active {
  border: none;
  outline: none;
  box-shadow: none;
}

/* zoom image */
.zoomModal {
  display: flex;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;

  margin: auto;
  background-color: #363636;
  transition: all 1.3s ease-in-out;
  border-radius: 0px;
  z-index: 1;
  justify-content: center;
}
.zoomImg {
  position: relative;
  margin-top: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: auto;
  border-radius: 0px;
  max-height: 70vh;
  z-index: 1;
  cursor: pointer;
}

.module {
  display: flex;
  min-height: 200px;
  flex-direction: column;
}
.module {
  overflow: hidden;
  overflow-y: scroll;
  height: 250px;
}
.module .fixed {
  top: 0;
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
}

.module::-webkit-scrollbar {
  width: 3px;
}
.module::-webkit-scrollbar-track {
  background: #202020;
}
.module::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}
.module::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

.module_list {
  color: #fad230;
  align-items: center;
  margin-bottom: 15px;
}

.module_list span {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.module_list .checkbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.module_list .module_item {
  margin-right: 20px;
}

.linebreak {
  color: #e7cf6f;
}

/* .loginForm{
     width: 100%;
    height: 100vh;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.loginForm .card{
    width: 450px;
    border: 1px solid #14A2B8;
    border-radius: 5px;
    padding: 30px;
     box-shadow: 5px 10px 8px 8px #202020ad;


}
.loginForm .card h4{
    color: #14A2B8;
    padding: 0;
    margin: 10px;
    font-size: 30px;
    align-items: center;
}
.loginForm .card input{
    margin-bottom: 20px;
    background-color: transparent;
    padding-left: 12px;
    font-size: 14px;
}
.loginForm .card Button{
    cursor: pointer;
    float: right;
}
#login-btn {
  background-color: #027bff;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  display: flex;
  align-self: center;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  cursor: pointer;
  align-items: center;
  margin-right: 10px;
}
#login-btn a {
    font-size: 12px;
  text-align: center;
  color: white;
  text-decoration: none;
} */
.lineup {
  display: flex;
  justify-content: center;
  width: 65%;
  background: green;
  margin: 20px auto;
  padding: 10px 20px;
  flex-direction: column;
}
.teamInfo:last-child {
  border-top: none;
  /* border-bottom: 1px solid #fff; */
}
.teamInfo {
  display: flex;
  width: 100%;
  /* height: 50px; */
  margin-bottom: 0px;
  padding-bottom: 0px;
  align-items: center;
  color: #202020;
  /* border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff; */
  justify-content: center;
}
.teamInfo span {
  margin: 0px 15px 0px 15px;
  font-size: 14px;
  font-weight: bold;
}
.teamInfo span:first-child {
  font-size: 10px !important;
}
.teamInfo .teamName{
  color:#ffffff;
}
.teamInfo span:last-child {
  color: #fad230;
}
.pitch {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  border: 1px solid white;
  position: relative;
}
.pitch .home,
.pitch .away {
  display: flex;
  height: 450px;
  flex-direction: column;
}
.lineup .pitch .half {
  /* margin: 450px 0px 0px -17px; */
  margin-left: -10px;
  width: 693px;
  border: 1px solid #eee;
}

.lineup .pitch .half:before {
  position: absolute;
  margin: -63px 43%;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 2px solid #eee;
  content: "";
}

.lineup .pitch .box1 {
  margin: -11px 40%;
  width: 120px;
  height: 60px;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.lineup .pitch .pen1 {
  margin: 1px 21px;
  width: 75px;
  height: 35px;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.lineup .pitch .box2 {
  margin: -10px 40%;
  width: 120px;
  height: 60px;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-top: 2px solid #eee;
}

.lineup .pitch .pen2 {
  margin: 23px 21px;
  width: 75px;
  height: 35px;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-top: 2px solid #eee;
}

.home .lineup_row {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: auto;
  margin-top: 0px !important;
}
.home .lineup_row:nth-child(4) {
  width: 80%;
}
.home .lineup_row:last-child {
  margin-bottom: 14%;
}
.away .keeper {
  display: flex;
  width: 90%;
  margin: auto;
  margin-bottom: 0px !important;
  align-items: end;
  text-align: center;
  justify-content: space-around;
}
.away .lineup_row:nth-child(2) {
  width: 80%;
}
.away .lineup_row:last-child {
  width: 90%;
}

.away .lineup_row:first-child {
  margin-top: 14%;
  width: 90%;
}

.player {
  display: flex;
  justify-content: center;
  width: 40px;
  /* height: auto; */
  border-radius: 20px;
  align-items: center;
  text-align: center;
  font-size: 9px;
  color: #202020;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  transition: 0.3s linear all;
  z-index: 0;
  flex: 1 1;
  position: relative;
  cursor: pointer;

  max-width: 190px;
}
.player p {
  margin-bottom: 0px;
  color: #202020;
  /* font-weight: bold; */
}

.player .rating {
  position: absolute;
  top: 10%;
  left: 45%;
  margin: 0px auto;
  transform: translate(-50%, -50%);
  
}
.badge {
  display: inline-block;
  padding: 3px 7px;
  font-size: 9px;
  font-weight: 700;
  line-height: 1;
  color: #202020;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 20px;
  /* background: red; */
}

.home .player .arrow-badge {
  position: absolute;
  top: -9%;
  left: 30%;
  margin: 0px 5px;
  transform: translate(-50%, -50%) !important;
}
.away .player .arrow-badge {
  position: absolute;
  top: -20%;
  left: 20%;
  margin: 0px 5px;
}

.arrow-badge {
  position: absolute;
  padding: 1px;
  border-radius: 50%;
  font-size: 7px;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bolder;
  line-height: 1;
  color: white;
  text-align: center;
  vertical-align: baseline;
}
.timeSubbedOff {
  padding-left: 12px;
  color: #202020;
  font-size: 9px;
}
.arrow {
   
  position: absolute;
  padding: 1px;
  border-radius: 50%;
  font-size: 7px;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bolder;
  color: white;
  text-align: center;
  background: #202020;
  border: 2px solid white;
  vertical-align: baseline;
}
.home .keeper img {
  background-color: #0f4b17;
  padding: 3px;
}
.keeper .player {
  text-align: center;
}
.keeper p {
  min-width: 100px;
  text-align: center;
}
.home .keeper .rating {
  margin: 0px 35px !important;
}
.away .keeper .rating {
  margin: 0px 35px !important;
}

.away .keeper img {
  background-color: #2020209a;
  padding: 3px;
}
.player img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

/* lineup_btn */
.lineup_btn {
  margin-top: 10px;
}

/* Bench */
.benchContiner {
  display: flex;
  padding: 15px;
  margin-top: 20px;
}
.coach {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #202020;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  margin: 20px 0px;
}
.coach .left,
.coach .right {
  min-width: 520px;
}
.coach .right {
  display: flex;
  align-items: center;
  justify-content: end;
}
.coach .center {
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(#202020, #fad230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 24px;
}
.bench {
  justify-content: center;
}
.bench h3 {
  font-size: 24px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  background: -webkit-linear-gradient(#202020, #fad230);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
}
.bench_player {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin: 10px 0px;
  font-size: 14px;
  font-weight: bold;
}
.bench_list {
  display: flex;
  min-width: 530px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 13px 0px;
}
.coach img,
.bench img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #0f4b17b1;
  /* background-color: #202020b5; */
  padding: 5px;
}
.coach .left img,
.bench .left img {
  margin-right: 14px;
}
.coach .right img,
.bench .right img {
  margin-left: 14px;
}
.bench_player .left span {
  padding: 0 12px;
}
.bench_player .right span {
  padding: 0 12px;
}
.bench_rating {
  display: inline-block;
  background-color: orange;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  font-size: 14px;
}
.bench_shirt {
  font-weight: normal;
}
.left small .bench_arrow,
.right small .bench_arrow {
  margin-left: 10px;
  padding: 1px 2px;
  border-radius: 50%;
  border: 3px solid #202020;
  background: #fff;
  color: #202020;
  font-weight: bold;
}

/* match facts */
.match_facts {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
}
.facts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  /* background: red; */
  /* margin: auto; */
}
.facts {
  font-size: 14px;
}
.facts .right {
  min-width: 515px;
  text-align: left;
  padding-left: 10px;
}
.facts .left {
  min-width: 515px;
  text-align: right;
  padding-right: 10px;
}
.facts .center {
  min-width: 100px;
  text-align: center;
  align-items: center;
}
.facts .center p {
  background: #f1f1f1;
  padding: 6px 12px;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.left .substitution {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
.substitution img {
  width: 35px;
  height: 35px;
}
.substitution p {
  margin-bottom: 0;
  font-size: 14px;
}
.substitution .name p:first-child {
  color: green;
}
.substitution .name p:last-child {
  color: red;
}
.right .substitution {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* display: inline-block; */
}

