/* .search .input-group{
  margin-bottom: 30px!important;
  justify-content: center!important;
}
*/

.search .input-group {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  /* height: 38px; */
  flex-wrap: nowrap;
}

.search-city .input-group input[type="text"],
.search-township .input-group input[type="text"],
.search-league .input-group input[type="text"],
.search-team .input-group input[type="text"],
.search-highlight .input-group input[type="text"] {
  width: 34% !important;
}

.search .input-group input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  width: 45%;
  height: 37px;
  background-color: #f1f1f1 !important;
}

.search .input-group .input-group-text,
.match-search-form .input-group .input-group-text,
form.searchModal .input-group .input-group-text {
  font-size: 22px !important;
  font-weight: 500 !important;
  width: 4%;
  height: 37px;
  justify-content: end;
  line-height: 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid gray !important;
  border-bottom: 1px solid gray !important;
  cursor: pointer;
  background-color: #f1f1f1 !important;
}

.search-player .input-group input[type="text"] {
  width: 50% !important;
  font-size: 14px;
}
.search .input-group button,
form.searchModal button {
  width: 10%;
  height: 37px;
  outline: none;
  box-shadow: none;
  /* border: none; */
  padding: 5px 12px;
  background: #202020;
  color: #fad230;
  font-size: 17px;
  border: 1px solid grey;
  cursor: pointer;
}
.search .input-group .clear_filter {
  width: 7%;
  height: 37px;
  outline: none;
  box-shadow: none;
  /* border: none; */
  padding: 5px 12px;
  background: #f1f1f1;
  color: #202020;
  font-weight: bolder;
  border: 1px solid grey;
  cursor: pointer;

  font-size: 22px !important;
  font-weight: 500 !important;
  /* height: 37px; */
  /* justify-content: end; */
  line-height: 1 !important;
}
form.searchModal button {
  width: 15% !important;
}

.search-wallet .search_select,
.user-fav-team .search_select {
  width: 45% !important;
}
/* .search-highlight .search_select, .search-livestream .search_select{
  height: 37px!important;
} */
.search .search_select {
  text-align: left;
  padding: 5px 10px;
  font-size: 15px;
  width: 34%;
  height: 37px;
  cursor: pointer;
  background: #f1f1f1;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}
.search_select_team .search_select {
  width: 50% !important;
}
.search.search-team .search_select,
.search.search-highlight .search_select {
  width: 45%;
}
.search.search-player .search_select {
  width: 48%;
}
.search.search-livestream .search_select {
  width: 50%;
}

.dropdown_search span {
  float: right;
}

form.search button:hover i {
  color: #fad230;
}

form input:focus,
form select:focus {
  outline: none;
}

/* search form from dropdown */
form.searchModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}

form.searchModal input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  float: left;
  width: 360px;
  height: 37px;
  background-color: #f1f1f1 !important;
}

form.searchModal button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* line break */
.lineBreak {
  margin: 30px 0px;
}

/* model box */
.searchSelectModal {
  position: absolute;
  top: 18.3%;
  left: 43.4%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 37.7%;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  outline: none;
}

.futureDetailSearch {
  position: absolute;
  top: 31%;
  left: 43.4%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 37.7%;
  height: auto;
  padding: 20px;
  border: 1px solid #202020;
  outline: none;
}

.searchSelectModal .close {
  position: absolute;
  top: 0;
  right: 5%;
  padding: 10px;
  font-size: 20px;
  color: #ff0000;
  font-weight: 900;
  cursor: pointer;
}

.searchSelectModal .item-lists .modelSearchInput {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  height: 35px;
  min-width: 150px;
  margin-bottom: 20px;
  outline: none;
  background-color: #f1f1f1;
}

.searchSelectModal .item-lists {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  color: #202020;
  padding: 10px;
  margin-bottom: 20px;
}

.searchSelectModal .item-lists p {
  padding: 5px 10px;
  border: 1px solid #202020;
  border-radius: 5px;
  cursor: pointer;
  height: 35px;
  min-width: 160px;
  margin-right: 10px;
}

.searchSelectModal .item-lists p:hover {
  background-color: #202020;
  color: #fad230;
}

.searchSelectModal .item-lists .searchFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.searchSelectModal .item-lists .searchFooter span {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  height: 35px;
  width: 48%;
}

.searchSelectModal .item-lists .loadMore {
  border: 1px solid #202020;
  background: #202020;
  color: #fad230;
}

.searchSelectModal .item-lists .loadMore:hover {
  background-color: #fad230;
  color: #202020;
}

.searchSelectModal .item-lists .stopSearching {
  border: 1px solid #202020;
  color: #202020;
  background-color: #fad230;
}

.searchSelectModal .item-lists .stopSearching:hover {
  color: #fad230;
  background-color: #202020;
}

.searchSelectModal .item-lists {
  overflow-y: scroll;
  max-height: 45vh;
}

.searchSelectModal .item-lists::-webkit-scrollbar {
  width: 5px;
}

.searchSelectModal .item-lists::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 25px;
}

.searchSelectModal .item-lists::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#202020, #fad230);
  border-radius: 25px;
}

.searchSelectModal .item-lists::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#fad230, #ff6a00);
}

.user-team-activity .input-group .search_select {
  width: 45% !important;
}
.match-quiz-search .input-group .search_select {
  width: 45% !important;
}
/* style for search_form */

.match-search-form .input-group {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  flex-wrap: nowrap;
}

.match-search-form .input-group input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  width: 16%;
  height: 37px;
  background-color: #f1f1f1 !important;
}

.match-search-form .input-group input[type="time" i] {
  font-size: 18px !important;
  align-items: center !important;
  font-weight: bolder !important;
  border: 1px solid grey !important;
  float: left !important;
  cursor: pointer;
  width: 4% !important;
  height: 37px !important;
  justify-content: center;
  padding: 0px 0px 0px 8px !important;
  background: #f1f1f1 !important;
  border-left: none !important;
  border-right: 2px solid gray !important;
}

.match-search-form .input-group input[type="date"] {
  font-size: 16px;
  align-items: center;
  font-weight: normal;
  border: 1px solid grey;
  float: left;
  width: 14%;
  height: 37px;
  padding: 0px 0px 0px 7px;
  background: #f1f1f1;
  border-left: none;
  cursor: pointer;
  border-right: 1px solid gray;
}


.match-search-form .input-group .search_select {
  padding: 5px 10px;
  font-size: 14px;
  width: 21%;
  height: 37px;
  cursor: pointer;
  background: #f1f1f1;
  border-right: 2px solid gray;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.match-search-form .input-group button {
  width: 7%;
  height: 37px;
  outline: none;
  box-shadow: none;
  padding: 5px 12px;
  background: #202020;
  color: #fad230;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}

.match-search-form
  .input-group
  input[type="date"]::-webkit-calendar-picker-indicator,
.match-search-form
  .input-group
  input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.search-prize .input-group input[type="text"] {
  padding: 5px 10px;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  width: 30%;
  height: 37px;
  background-color: #f1f1f1 !important;
}
.search-prize .input-group input[type="date"] {
  font-size: 16px;
  align-items: center;
  font-weight: normal;
  border: 1px solid grey;
  float: left;
  width: 28%;
  height: 37px;
  padding: 0px 0px 0px 7px;
  background: #f1f1f1;
  border-left: none;
  cursor: pointer;
  border-right: 2px solid gray;
  border-left: 1px solid gray;
}
/* score */
form.search-score {
  /* width:500px; */
  justify-content: end;
  text-align: right;
}
form.search-score input[type="text"] {
  width: 100% !important;
}
form.search-score .input-group button {
  width: auto !important;
  margin-right: 0;
}

/* search with match quiz in quiz_details */
.item-lists .search_with_quiz {
  display: flex;
  width: 100% !important;
  min-width: 420px;
  /* min-width: 500px; */
}
.search_with_quiz {
  text-align: left;
  border: 1px solid #202020;
  border-radius: 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
  padding: 10px;
}
.loadMoreQuiz {
  min-width: 420px;
}
.search.activity_search .search_select {
  width: 50% !important;
}

.search-prize-history {
  width: 80% !important;
}
.search-prize-history .search_select {
  width: 38% !important;
}
.search-prize-history .input-group input[type="date"] {
  font-size: 16px;
  align-items: center;
  font-weight: normal;
  border: 1px solid grey;
  float: left;
  width: 38%;
  height: 37px;
  padding: 0px 0px 0px 7px;
  background: #f1f1f1;
  border-left: none;
  cursor: pointer;
  border-left: 1px solid gray;
}
